.nav{
  padding: 2%;
  border-bottom: 2px solid gray;
}

#header-logo{
  width: 15%;
}

.nav-link{
  color: black;  
}

.nav-item:first-of-type{
  
  padding-left: 5%;
}

#today-day-heading{
  margin-top: 1%;
  text-align: center;
}

#parents-page-heading{
  margin-top: 1%;
  text-align: center;
}
#prize-page-header{
  margin-top: 1%;
  text-align: center;
}

#kids-progress-card{
  /* width: 10%; */
  margin-left: 2%;
  margin-top: 2%;
}

.card-header{
  font-weight: bold;
}

#main-page-row{
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin: 2% 10% 0% 10%;

}

#kids-progress-column > row{
  width:20%
  
}

#task-card{
  margin: 1%;

}

#add-a-task-form{
  /* margin: 2%;
  padding: 1rem;
  border: 2px solid gray;
  border-radius: 10px;
  box-shadow: 5px 5px 5px gray; */
}

#add-task-accordion{
  margin: 2%;
  border: 2px solid gray;
  background-color: transparent;
  border-radius: 5px;
  box-shadow: 5px 5px 5px gray;
}

/* removing background of accordion so i can have a border radius */
.accordion{
  --bs-accordion-bg: transparent;
}

.accordion-button:not(.collapsed){
  color: black;
  background-color: white;
}

.accordion-button:focus{
  border-color: none;
}

.accordion-button{
  font-size: 1.3rem;
  font-weight: bold;
}

#prize-container{
  display: flex;
  flex-wrap: wrap;
  padding: 2%;
  justify-content: center;
}

#prize-card{
  margin: 1%;
}

#assigned-tabs-row{
  max-width: 70%;
  display: grid;
  /* justify-content: center; */
  margin: auto;
}

.card-header{
  display: flex;
  justify-content: space-between;
}

#edit-task:hover{
  cursor: pointer;
}

#complete-checkbox-for-kids{
  margin: 1% 0% 1% 2%;
}

/* ############################################ */
/* #   Mobile CSS                             # */
/* ############################################ */

@media screen and (max-width: 992px) {
 
  #today-day-heading{
    margin-top: 20px;
    margin-bottom: 20px;
  }

  #main-page-row{
    display: grid;
    grid-template-columns: 1fr;
    padding: 0px;
    margin: 0%;
  
  }

  #kids-progress-card{
    display: none;
  }

  #home-page-child-tabs{
    min-width: 100%;
  }

  .nav{
    padding: 0px;
  }

}